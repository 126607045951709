<template>
  <div>
    <div class="d-flex flex-column flex-lg-row">
      <div class="d-flex flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px">
        <BlockIfLoading :isLoading="isLoading">
          <div class="card card-filter-nav d-flex flex-column card-custom">
            <perfect-scrollbar class="scroll">
              <div class="card-body overflow-y-auto">
                <div class="d-flex justify-content-center">
                  <KundeDublettenConfirmationFilter
                    @onClickSaveFilter="onSaveFilter"
                    @onClickResetFilter="onResetFilter"
                  ></KundeDublettenConfirmationFilter>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </BlockIfLoading>
      </div>
      <div class="flex-lg-row-fluid ml-lg-7 ml-xl-10">
        <KundeDublettenConfirmationTable
          :filter="kundeDublettenConfirmationFilterArray"
          :appliedFilter="appliedKundeDublettenConfirmationFilter"
          :expand="kundeDublettenExpand"
        ></KundeDublettenConfirmationTable>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}
</style>
<script>
import { SET_APPLIED_FILTER } from '@/core/kunden/stores/kunden-dubletten-confirmation-filter.module';
import KundeDublettenConfirmationTable from '@/components/kunden/dubletten-confirmation-table.vue';
import KundeDublettenConfirmationFilter from '@/components/kunden/dubletten-confirmation-filter.vue';
import BlockIfLoading from '@/components/common/block-if-loading.vue';

import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    KundeDublettenConfirmationTable,
    KundeDublettenConfirmationFilter,
    BlockIfLoading,
  },
  computed: {
    ...mapGetters(['kundeDublettenConfirmationFilterArray']),
    ...mapState({
      isLoading: state => state.kundeDublettenConfirmationFilter.isLoading,
      kundeDublettenExpand: state => state.kundeDublettenConfirmationFilter.expand,
      appliedKundeDublettenConfirmationFilter: state =>
        state.kundeDublettenConfirmationFilter.appliedKundeDublettenConfirmationFilter,
      kundeDublettenConfirmationFilter: state => state.kundeDublettenConfirmationFilter.filter,
    }),
  },
  methods: {
    onSaveFilter() {
      this.$store.commit(SET_APPLIED_FILTER, this.kundeDublettenConfirmationFilter);
    },
    onResetFilter() {
      this.$store.commit(SET_APPLIED_FILTER, this.kundeDublettenConfirmationFilter);
    },
    onSortChange() {
      this.$store.commit(SET_APPLIED_FILTER, this.kundeDublettenConfirmationFilter);
    },
    onPageChange(ctx) {
      this.skip = ctx.skip;
      this.top = ctx.top;
    },
  },
};
</script>
