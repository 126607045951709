<template>
  <div class="d-flex">
    <b-table-simple class="table table-sm">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-muted">{{ $t('KUNDEN.KUNDENNUMMER') }}:</b-td>
          <b-td>{{ kunde.id || '-' }}</b-td>
          <b-td>{{ duplicateKunde.id || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.nameMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('REISEN.COMMON.NAME') }}: </b-td>
          <b-td>{{ kunde.name || '-' }}</b-td>
          <b-td>{{ duplicateKunde.name || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.emailMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('AUTH.INPUT.EMAIL') }}: </b-td>
          <b-td>{{ kunde.email || '-' }}</b-td>
          <b-td>{{ duplicateKunde.email || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.adresseMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.ADRESSE') }}: </b-td>
          <b-td>{{ kunde.adresse || '-' }}</b-td>
          <b-td>{{ duplicateKunde.adresse || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.telefonMobilMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.TELMOBILSHORT') }}: </b-td>
          <b-td>{{ kunde.telefonMobil || '-' }}</b-td>
          <b-td>{{ duplicateKunde.telefonMobil || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.telefonPrivatMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.TELEFONPRIVATSHORT') }}: </b-td>
          <b-td>{{ kunde.telefonPrivat || '-' }}</b-td>
          <b-td>{{ duplicateKunde.telefonPrivat || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[kunde.telefonGeschaeftlichMatch ? 'bg-success-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.TELGESCHAEFTLICHSHORT') }}: </b-td>
          <b-td>{{ kunde.telefonGeschaeftlich || '-' }}</b-td>
          <b-td>{{ duplicateKunde.telefonGeschaeftlich || '-' }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  props: {
    kunde: { type: Object, required: true },
    duplicateKunde: { type: Object, required: true },
  },
  data() {
    return {};
  },
  methods: {
    hasDiff(kunde) {
      return !(
        kunde.nameDiff ||
        kunde.emailDiff ||
        kunde.adressediff ||
        kunde.telefonPrivatDiff ||
        kunde.telefonGeschaeftlichDiff ||
        kunde.telefonMobilDiff
      );
    },
  },
};
</script>
