<template>
  <div>
    <TableAggregationsToolbar
      v-if="!isInitialLoading && aggregations"
      :aggregations="aggregations"
    ></TableAggregationsToolbar>
    <b-skeleton v-if="isInitialLoading" width="300px" height="24px"></b-skeleton>
  </div>
</template>

<script>
import TableAggregationsToolbar from '@/components/common/table-aggregations-toolbar';
import apiService from '@/core/common/services/api.service';

export default {
  components: { TableAggregationsToolbar },
  props: {
    isParentLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      isInitialLoading: true,
      aggregations: null,
    };
  },
  watch: {
    isParentLoading: {
      immediate: true,
      handler: async function (isParentLoading) {
        if (isParentLoading) {
          const odataAggregate = await this.getAggregationsByFilter();
          const transformedAggregate = this.transformAggregateDate(odataAggregate);
          this.aggregations = [
            {
              data: transformedAggregate.New || '-',
              title: this.$t('COMMON.NEU'),
              iconClass: 'far fa-file text-primary',
            },
            {
              data: transformedAggregate.Merged || '-',
              title: this.$t('DUBLETTEN.CONFIRMATION.MERGED'),
              iconClass: 'fas fa-check text-success',
            },
            {
              data: transformedAggregate.Declined || '-',
              title: this.$t('DUBLETTEN.CONFIRMATION.DECLINED'),
              iconClass: 'fas fa-ban text-danger',
            },
            {
              data: transformedAggregate.total || '-',
              title: this.$t('COMMON.INSGESAMT'),
              iconClass: 'fas fa-user text-grey',
            },
          ];
        }
      },
    },
  },
  methods: {
    async getAggregationsByFilter() {
      this.isLoading = true;
      return apiService
        .get('KundeDuplicate?$apply=groupby(((state)),aggregate($count%20as%20$count))&$count=true')
        .then(response => {
          return response.data.result.value;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.isInitialLoading = false;
        });
    },
    transformAggregateDate(data) {
      return data.reduce(
        (acc, cur) => {
          if (!acc.hasOwnProperty(cur.state)) {
            acc[cur.state] = cur.$count;
            acc.total += cur.$count;
          }
          return acc;
        },
        { total: 0 }
      );
    },
  },
};
</script>
