<template>
  <div class="rounded border p-2">
    <b-form-radio-group
      class="w-100"
      v-model="selected"
      :options="filterOptions"
      buttons
      block
      stacked
      size="sm"
      button-variant="secondary"
      @input="$emit('input', $event)"
    ></b-form-radio-group>
  </div>
</template>

<script>
import dublettenConfirmationStateFilter from '@/core/common/dubletten-state-filter.config.json';

export default {
  components: {},
  props: {
    value: { type: String, default: 'default' },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    filterOptions() {
      return Object.keys(dublettenConfirmationStateFilter).map(key => {
        return { text: this.$t(`DUBLETTEN.CONFIRMATION.${key.toUpperCase()}`), value: key };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (v) {
        this.selected = v === null ? [] : v;
      },
    },
  },
};
</script>
