<template>
  <div>
    <portal to="subheader-left">
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <h5 class="text-dark font-weight-bold my-5 mr-5">{{ $t('KUNDEN.DUBLETTEN.PRUEFUNG') }}</h5>
        <div class="subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-200"></div>
        <KundeDublettenConfirmationTableToolbarBase
          :isParentLoading="isBusy"
        ></KundeDublettenConfirmationTableToolbarBase>
      </div>
    </portal>
    <portal to="subheader-right">
      <b-button variant="primary" size="sm" @click="$bvModal.show('modal-kunde-duplicates-merge')">{{
        $t('DUBLETTEN.MANUALMERGE')
      }}</b-button>
      <KundenDuplicatesMergeModal @duplicate-created="refreshKunden()"></KundenDuplicatesMergeModal>
    </portal>
    <div class="d-flex flex-column flex-lg-row">
      <div v-if="kundenDuplicates" class="flex-lg-row-fluid">
        <div
          v-for="kundeDuplicate in kundenDuplicates"
          :key="kundeDuplicate.id"
          class="card card-custom w-100 gutter-b confirmation-card"
          :class="{
            'overlay overlay-block overlay-layer bg-dark-o-10': isBusy,
            hidden: kundeDuplicate.hidden,
          }"
        >
          <div class="card-header ribbon ribbon-top">
            <div class="ribbon-target mb-2" :class="kundeDuplicate.state.toLowerCase()">
              {{ $t('DUBLETTEN.CONFIRMATION.' + kundeDuplicate.state.toUpperCase()) }}
            </div>
            <div class="card-title">
              <h3 class="card-label"></h3>
            </div>
            <div class="card-toolbar text-muted">
              <template v-if="kundeDuplicate.resultCodes && kundeDuplicate.resultCodes.length > 0">
                <b-icon-journal-code title="Result Codes" class="ml-5 mr-1 mb-0 h5"></b-icon-journal-code>
                <MelissaCode
                  v-for="(code, index) in kundeDuplicate.resultCodes"
                  :key="index"
                  :isSeperated="index !== kundeDuplicate.resultCodes.length - 1"
                  :code="code"
                />
              </template>
              <template v-if="kundeDuplicate.errorCodes && kundeDuplicate.errorCodes.length > 0">
                <b-icon-journal-x title="Error Codes" class="ml-5 mr-1 mb-0 h5"></b-icon-journal-x>
                <MelissaCode
                  v-for="(code, index) in kundeDuplicate.errorCodes"
                  :isSeperated="index !== kundeDuplicate.errorCodes.length - 1"
                  :key="index"
                  :code="code"
                />
              </template>

              <b-icon-arrow-repeat title="Updated" class="ml-5 mr-1 mb-0 h5"></b-icon-arrow-repeat>
              {{ kundeDuplicate.updatedDateUtc | dateTime }}
              <b-icon-file-earmark title="Erstellt" class="ml-5 mr-1 mb-0 h5"></b-icon-file-earmark>
              {{ kundeDuplicate.createdDateUtc | dateTime }}
              <b-icon-person-check-fill
                :title="$t('COLUMNNAMES.Bearbeiter')"
                class="ml-5 mr-1 mb-0 h5"
              ></b-icon-person-check-fill>
              {{ $store.getters.getUsernameById(kundeDuplicate.bearbeiter) || '-' }}
            </div>
          </div>
          <div class="card-body">
            <KundeDublettenConfirmationCompareTable
              :kunde="kundeDuplicate.kunde"
              :duplicateKunde="kundeDuplicate.duplicateKunde"
            />
          </div>
          <div
            v-if="
              kundeDuplicate.state === 'New' ||
              kundeDuplicate.state === 'Declined' ||
              kundeDuplicate.state === 'Merged'
            "
            class="card-footer d-flex justify-content-between"
          >
            <b-button
              v-if="kundeDuplicate.state === 'New' || kundeDuplicate.state === 'Declined'"
              @click="onSendValidationClick(kundeDuplicate, 0)"
              variant="primary"
              class="btn btn-primary mr-1"
            >
              <b-icon-check2-circle class="mr-1"></b-icon-check2-circle
              >{{ kundeDuplicate.state === 'New' ? 'Annehmen' : 'Korrektur: Annehmen' }}</b-button
            >
            <b-button
              v-if="kundeDuplicate.state === 'New'"
              @click="onSendValidationClick(kundeDuplicate, 1)"
              variant="secondary"
              class="btn btn-primary"
            >
              <b-icon-x-circle class="mr-1"></b-icon-x-circle>Ablehnen</b-button
            >
            <b-button
              v-if="kundeDuplicate.state === 'Merged'"
              @click="onSendValidationClick(kundeDuplicate, 2)"
              variant="warning"
              class="btn btn-primary"
            >
              <b-icon-x-circle class="mr-1"></b-icon-x-circle>Merge rückgängig machen</b-button
            >
          </div>
        </div>

        <div v-if="allItemsCount > top || allItemsCount === 0" class="card card-custom">
          <div class="card-body">
            <LoadSpinnerWidget
              v-if="allItemsCount === 0"
              :dataAvailable="allItemsCount !== 0"
              :isLoading="false"
              :showText="true"
            ></LoadSpinnerWidget>
            <SKRPagination
              v-if="allItemsCount > top"
              :overwriteCurrentPage="currentPage"
              :totalRows="allItemsCount"
              :isLoading="isBusy"
              :perPageOverwrite="top"
              :currentRows="kundenDuplicates.length"
              @pageChange="onPageChange"
            ></SKRPagination>
          </div>
        </div>
      </div>
      <div v-else class="w-100">
        <b-skeleton v-for="n in top" :key="n" class="gutter-b" width="100%" height="377px"></b-skeleton>
      </div>
    </div>

    <KundeDublettenConfirmationModal
      modalId="confirmation-modal"
      :kunde="modalKunde"
      :duplicateKunde="modalDuplicateKunde"
      :trigger="modalTrigger"
      @onOkayClick="onConfirmationModalOkayClick($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

.ribbon-target {
  &.new {
    background-color: $primary;
  }
  &.merged {
    background-color: $success;
  }
  &.declined {
    background-color: $danger;
  }
}

.confirmation-card {
  transition: 0.3s ease-out;
  opacity: 1;
  &.hidden {
    opacity: 0;
    left: 10px;
  }
}
</style>

<script>
import { SET_KUNDEN_CONFRIMATION_IS_LOADING } from '@/core/kunden/stores/kunden-dubletten-confirmation-filter.module';
import EventBus from '@/core/common/plugins/event-bus.js';

import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';
import SKRPagination from '@/components/common/skr-pagination.vue';

import KundeDublettenConfirmationTableToolbarBase from '@/components/kunden/dubletten-confirmation-table-toolbar-base.vue';
import KundeDublettenConfirmationCompareTable from '@/components/kunden/dubletten-confirmation-table-compare-table.vue';
import KundeDublettenConfirmationModal from '@/components/kunden/dubletten-confirmation-modal.vue';
import KundenDuplicatesMergeModal from '@/components/kunden/kunden-duplicates-merge-modal.vue';

import MelissaCode from '@/components/common/melissa-code.vue';

export default {
  components: {
    LoadSpinnerWidget,
    SKRPagination,
    KundeDublettenConfirmationCompareTable,
    MelissaCode,
    KundeDublettenConfirmationTableToolbarBase,
    KundeDublettenConfirmationModal,
    KundenDuplicatesMergeModal,
  },
  props: {
    filter: { type: Array, required: true },
    appliedFilter: { type: Object, required: true },
    expand: { type: Object, required: true },
  },
  data() {
    return {
      currentPage: null,
      allItemsCount: 0,
      curItemsCount: null,
      kundenDuplicates: null,
      top: 10,
      skip: 0,
      isBusy: false,

      modalKunde: null,
      modalDuplicateKunde: null,
      modalTrigger: null,
    };
  },
  watch: {
    async appliedFilter() {
      this.skip = 0;
      this.currentPage = 1;
      this.refreshKunden();
    },
  },
  async mounted() {
    this.refreshKunden();

    EventBus.$on('reload', async () => {
      this.refreshKunden();
    });
  },
  methods: {
    onConfirmationModalOkayClick($event) {
      this.sendTrigger($event.kundeId, $event.duplicateKundeId, $event.trigger);
    },
    async sendTrigger(kundeId, duplicateKundeId, trigger) {
      const index = this.kundenDuplicates.findIndex(
        duplicate => duplicate.kundeId === kundeId && duplicate.duplicateKundeId === duplicateKundeId
      );

      this.saveMergeStates(kundeId, duplicateKundeId, trigger).then(data => {
        // Wait for animation (300ms) and then refresh
        this.$set(this.kundenDuplicates, index, {
          ...this.kundenDuplicates[index],
          hidden: true,
        });
        setTimeout(() => {
          this.refreshKunden();
        }, 400);
      });
    },
    onSendValidationClick(kundeDuplicate, trigger) {
      if (kundeDuplicate.kunde.nameMatch) {
        this.sendTrigger(kundeDuplicate.kundeId, kundeDuplicate.duplicateKundeId, trigger);
      } else {
        this.modalKunde = kundeDuplicate.kunde;
        this.modalDuplicateKunde = kundeDuplicate.duplicateKunde;
        this.modalTrigger = trigger;
        this.$bvModal.show('confirmation-modal');
      }
    },
    async onPageChange(ctx) {
      this.currentPage = ctx.page;
      this.skip = ctx.skip;
      this.top = ctx.top;
      window.scrollTo(0, 0);
      await this.refreshKunden();
    },
    getValueAndValidation(item) {
      return item ? item.value : '';
    },
    async refreshKunden() {
      this.kundenDuplicates = await this.getKundenData(this.skip, this.top);
    },
    parseKundenDuplicatesValuesAndAddDiff(kundenDuplicates) {
      return kundenDuplicates.map(kundenDuplicate => {
        const duplicateKunde = kundenDuplicate.duplicateKunde;
        const kunde = kundenDuplicate.kunde;
        if (kunde && duplicateKunde) {
          if (duplicateKunde.name && kunde.name) {
            kunde.name = `${kunde.name.anrede || ''} ${kunde.name.titel || ''} ${kunde.name.vorname || ''} ${
              kunde.name.nachname || ''
            }`;
            duplicateKunde.name = `${duplicateKunde.name.anrede || ''} ${duplicateKunde.name.titel || ''} ${
              duplicateKunde.name.vorname || ''
            } ${duplicateKunde.name.nachname || ''}`;
            kunde.nameDiff = kunde.name !== duplicateKunde.name;
          }
          if (duplicateKunde.email && kunde.email) {
            kunde.emailDiff = kunde.email !== duplicateKunde.email;
          }
          if (duplicateKunde.adresse && kunde.adresse) {
            kunde.adresse = this.parseAdresse(kunde.adresse);
            duplicateKunde.adresse = this.parseAdresse(duplicateKunde.adresse);
            kunde.adresseDiff = kunde.adresse !== duplicateKunde.adresse;
          }
          if (duplicateKunde.telefonPrivat && kunde.telefonPrivat) {
            kunde.telefonPrivat = this.getValueAndValidation(kunde.telefonPrivat);
            duplicateKunde.telefonPrivat = this.getValueAndValidation(duplicateKunde.telefonPrivat);
            kunde.telefonPrivatDiff = kunde.telefonPrivat !== duplicateKunde.telefonPrivat;
          }
          if (duplicateKunde.telefonGeschaeftlich && kunde.telefonGeschaeftlich) {
            kunde.telefonGeschaeftlich = this.getValueAndValidation(kunde.telefonGeschaeftlich);
            duplicateKunde.telefonGeschaeftlich = this.getValueAndValidation(
              duplicateKunde.telefonGeschaeftlich
            );
            kunde.telefonGeschaeftlichDiff =
              kunde.telefonGeschaeftlich !== duplicateKunde.telefonGeschaeftlich;
          }
          if (duplicateKunde.telefonMobil && kunde.telefonMobil) {
            kunde.telefonMobil = this.getValueAndValidation(kunde.telefonMobil);
            duplicateKunde.telefonMobil = this.getValueAndValidation(duplicateKunde.telefonMobil);
            kunde.telefonMobilDiff = kunde.telefonMobil !== duplicateKunde.telefonMobil;
          }
          kunde.nameMatch = kundenDuplicate.resultCodes.includes('MS06');
          kunde.emailMatch = kundenDuplicate.resultCodes.includes('MS07');
          kunde.adresseMatch = kundenDuplicate.resultCodes.includes('MS06');
          kunde.telefonPrivatMatch = kundenDuplicate.resultCodes.includes('MS09');
          kunde.telefonGeschaeftlichMatch = kundenDuplicate.resultCodes.includes('MS10');
          kunde.telefonMobilMatch = kundenDuplicate.resultCodes.includes('MS08');
          return { ...kundenDuplicate };
        }
      });
    },
    parseAdresse(adresse) {
      return adresse
        ? `${adresse.strasse?.value || ''}, ${adresse.postleitzahl?.value || ''} ${
            adresse.ort?.value || ''
          } ${adresse.land?.value || ''}`
        : '';
    },
    async getKundenData(skip, top) {
      this.$store.commit(SET_KUNDEN_CONFRIMATION_IS_LOADING, true);
      this.isBusy = true;
      return odataService
        .getKundeDuplicate({
          filter: this.filter,
          expand: this.expand,
          top: top,
          skip: skip,
          count: true,
          orderBy: 'updatedDateUtc desc',
        })
        .then(response => {
          this.allItemsCount = response.count;
          this.isBusy = false;
          return this.parseKundenDuplicatesValuesAndAddDiff(response.data);
        })
        .finally(() => {
          this.isBusy = false;
          this.$store.commit(SET_KUNDEN_CONFRIMATION_IS_LOADING, false);
        });
    },
    async saveMergeStates(id, duplicateKundeId, trigger) {
      return apiService
        .put(`Kunde/${id}/KundenDuplicates/${duplicateKundeId}`, {
          id: id,
          duplicateKundeId: duplicateKundeId,
          trigger: trigger,
        })
        .then(data => {
          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          return data;
        })
        .catch(err => {
          throw err;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
